import React from 'react';

import {Check, CircleNotch} from 'phosphor-react';
import DateInput from 'components/common/date-picker';

function SurveyStats({surveyStats, startDate, endDate, handleStartDate, handleEndDate}) {
  const {totalQuestions, completedSurveys, pendingSurveys, inProgressSurveys, activeMembersCount} = surveyStats;

  return (
    <div className="row">
      <div className="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3 state-card-container">
        <div className="state-card">
          <div className="d-flex flex-column">
            <p className="opacity-50">Surveys completed</p>
            <div className="d-flex justify-content-between align-items-center flex-row">
              <p className="f-w">{completedSurveys}</p>
              <Check size={40} className="clr" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3 state-card-container">
        <div className="state-card">
          <div className="d-flex flex-column">
            <p className="opacity-50">Surveys in progress</p>
            <div className="d-flex justify-content-between align-items-center flex-row">
              <p className="f-w">{inProgressSurveys}</p>
              <CircleNotch size={40} className="clr" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3 state-card-container">
        <div className="state-card">
          <div className="d-flex flex-column">
            <p className="opacity-50">Active MIF Members</p>
            <div className="d-flex justify-content-between align-items-center flex-row">
              <p className="f-w">{activeMembersCount}</p>
              {/* <CircleNotch size={40} className="clr" /> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-6 col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-3 state-card-container">
        <div className="state-card">
          <div className="d-flex flex-column">
            <p className="opacity-50 mb-1">From</p>
            <DateInput
              className="m-0 mb-1"
              value={startDate}
              handleChange={date => {
                handleStartDate(date);
              }}
            />
            <p className="opacity-50 mb-1">To</p>
            <DateInput
              className="m-0"
              value={endDate}
              handleChange={date => {
                handleEndDate(date);
              }}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default SurveyStats;
