import React, {useEffect, useState} from 'react';
import {getAnswersStats, getSurveyStats} from 'containers/dashboard/api';
import BSLoader from 'components/common/loader';
import SurveyStats from './SurveyStats';
import AnswersStats from './AnswersStats';
import './dashboard.scss';
import {convertToDDMMYYYY, formatCurrentDate} from 'utils/common';
import DateInput from 'components/common/date-picker';
import SelectComponent from 'components/common/select';

const Dashboardcontent = () => {
  const [surveyStats, setSurveyStats] = useState({
    totalQuestions: 'N/A',
    completedSurveys: 'N/A',
    pendingSurveys: 'N/A',
    inProgressSurveys: 'N/A',
    activeMembersCount: 'N/A',
  });
  const [answerStats, setAnswerStats] = useState([]);
  const [startDate, setStartDate] = useState(formatCurrentDate());
  const [endDate, setEndDate] = useState(formatCurrentDate());
  const [category, setCategory] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const mapSurveyStatstoState = statsResult => {
    const {
      total_questions = 'N/A',
      completed_surveys = 'N/A',
      pending_surveys = 'N/A',
      in_progress_surveys = 'N/A',
      active_members_count = 'N/A',
    } = statsResult;
    setSurveyStats({
      totalQuestions: total_questions,
      completedSurveys: completed_surveys,
      pendingSurveys: pending_surveys,
      inProgressSurveys: in_progress_surveys,
      activeMembersCount: active_members_count,
    });
  };
  const handleGetData = async () => {
    setIsLoadingData(true);
    try {
      const [surveyStatResult, answersStatsList] = await Promise.allSettled([
        getSurveyStats(convertToDDMMYYYY(startDate), convertToDDMMYYYY(endDate), category),
        getAnswersStats(convertToDDMMYYYY(startDate), convertToDDMMYYYY(endDate), category),
      ]);

      if (surveyStatResult.status === 'fulfilled') {
        mapSurveyStatstoState(surveyStatResult.value);
      }

      if (answersStatsList.status === 'fulfilled') {
        setAnswerStats(answersStatsList.value);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoadingData(false);
    }
  };
  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <div className="container-fluid py-4">
      <div className="state-card-container mb-3">
        <div className="state-card p-3 d-flex flex-column flex-md-row align-items-center gap-3 flex-wrap rounded shadow-sm bg-white">
          {/* Date Inputs */}
          <div className="d-flex flex-column flex-md-row align-items-center gap-2">
            <p className="text-secondary mb-0 fw-semibold">From</p>
            <DateInput className="py-1 px-2" value={startDate} handleChange={setStartDate} />
            <p className="text-secondary mb-0 fw-semibold">To</p>
            <DateInput className="py-1 px-2" value={endDate} handleChange={setEndDate} />
          </div>

          {/* Category Selection */}
          <div className="d-flex align-items-center gap-2">
            <label className="text-dark fw-semibold mb-0">Category</label>
            <SelectComponent
              className="py-1 px-2"
              selectedValue={category}
              handleChange={obj => setCategory(obj?.value || '')}
              options={[
                {value: 'CA', label: 'CA'},
                {value: 'WA', label: 'WA'},
              ]}
              placeholder="Select"
              isClearable
            />
          </div>

          {/* Filter Button */}
          <div className="ms-auto">
            <button className="primary-btn px-4 py-2 fw-bold" onClick={handleGetData}>
              Filter
            </button>
          </div>
        </div>
      </div>

      {isLoadingData ? (
        <div className="loader-wrapper">
          <BSLoader />
        </div>
      ) : (
        <>
          <SurveyStats surveyStats={surveyStats} />
          <AnswersStats answerStats={answerStats} />
        </>
      )}
    </div>
  );
};

export default Dashboardcontent;
